import * as React from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';

import { selectPMPEnabled } from 'ducks/server/settings';
import { selectBookingWidgetBannerImageUrls } from 'lib/util/bookingWidgetDesign';
import { selectTopPageBannerImageUrl } from 'lib/util/privateMarketplace';

export const OgpImageUrl = (): any => {
  const pmpEnabled = useSelector(selectPMPEnabled);

  const pmpBannerImageUrl = useSelector(selectTopPageBannerImageUrl);
  const bwBannerImageUrls = useSelector(selectBookingWidgetBannerImageUrls);

  let ogpImageUrl = '';
  if (pmpEnabled) {
    ogpImageUrl = pmpBannerImageUrl;
  } else {
    if (bwBannerImageUrls.length > 0) {
      ogpImageUrl = bwBannerImageUrls[0];
    }
  }

  return <Head>{ogpImageUrl && <meta property="og:image" content={ogpImageUrl} />}</Head>;
};
