import * as React from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';

import { ReduxState } from 'ducks';
import { selectPMPEnabled } from 'ducks/server/settings';
import { selectBookingWidgetHeaderDescription } from 'lib/util/bookingWidgetDesign';
import { selectHeaderDescriptionText } from 'lib/util/privateMarketplace';

export const OgpSiteName = (): any => {
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const pmpEnabled = useSelector(selectPMPEnabled);

  const pmpHeaderDescription = useSelector(selectHeaderDescriptionText);
  const bwHeaderDescription = useSelector(selectBookingWidgetHeaderDescription);

  let headerDescription = '';
  if (pmpEnabled) {
    headerDescription = pmpHeaderDescription;
  } else {
    headerDescription = bwHeaderDescription;
  }

  const ogpSiteName = `${headerDescription} ${settings?.supplier_name}`;

  return (
    <Head>
      <meta property="og:site_name" content={ogpSiteName} />
    </Head>
  );
};
