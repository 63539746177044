import * as React from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';

import { ReduxState } from 'ducks';

export const TwitterSite = (): any => {
  const settings = useSelector((state: ReduxState) => state.server.settings.all);

  const twitter = (settings?.sns_ids || []).find((snsId) => {
    return snsId?.key === 'twitter';
  });

  return <Head>{twitter?.id && <meta name="twitter:site" content={`@${twitter.id}`} />}</Head>;
};
